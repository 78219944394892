<template lang="pug">
    .root.text-right
        .jumbotron.pull-right()
            .container-fluid.text-right
                .label
                    h2.moto-text צור קשר - Buy Amazon Inc.
        .container.pt-3
            .row
                .col-sm-12
                    h5 אנחנו ב-
                        span.orange-text &nbsp;BUY AMAZON&nbsp;
                        | מאמינים כי לקוחותינו יקבלו את השירות הטוב ביותר. לשאלות נפוצות מומלץ לפנות ללינק
        .container.mt-5

            .row
                .col-sm-12.col-md-6
                    b-card.w-100.card-hight.mb-3(bg-variant='light')
                        h5.font-weight-bold דרכי תקשורת
                        hr
                        .label-info.pb-2
                            h6.font-weight-bold נשמח לסייע לכם בכל שאלה
                        table.w-100.mr-4.ml-2
                            tr
                                th
                                    label טלפון
                                    font-awesome-icon.icon.alt.mr-2(:icon="['fas', 'phone-alt']")
                                td
                                    label#con-phone 972-52-666-6666+
                            tr
                                th
                                    label  דוא"ל
                                    font-awesome-icon.icon.alt.mr-2(:icon="['fas', 'mail-bulk']")
                                td
                                    label#con-email support@ienigmax.club
                            tr
                                th
                                    label טלגרם
                                    font-awesome-icon.icon.alt.mr-2(:icon="['fab', 'telegram-plane']")
                                td
                                    label#con-telegram am.bot545

                .col-sm-12.col-md-6.gap-bottom
                    form()
                        .input-group
                            b-card.w-100.card-hight.second-card(bg-variant='light')
                                b-form-group.mb-0(label-cols-lg='3', label='השאר את פרטיך', label-size='lg', label-class='font-weight-bold pt-0')
                                    b-form-group(label-cols-sm='3', label='שם:', label-align-sm='right', label-for='nested-street', required="true")
                                        b-form-input#nested-name(v-bind:class="{'is-invalid': error.name}", placeholder="שם מלא", v-model="formData.name")
                                    b-form-group(label-cols-sm='3', label='דוא"ל:', label-align-sm='right', label-for='nested-city')
                                        b-form-input#nested-email(v-bind:class="{'is-invalid': error.email}", type="email", placeholder='כתובת דוא"ל', v-model="formData.email")
                                    b-form-group(label-cols-sm='3', label="מס' טלפון:", label-align-sm='right', label-for='nested-state')
                                        b-form-input#nested-phone(v-bind:class="{'is-invalid': error.phone}", type="number", placeholder="לדוגמא: +972 52-384-3333", v-model="formData.phone")
                                    b-form-group(label-cols-sm='3', label="מלל הבקשה:", label-align-sm='right', label-for='nested-state')
                                        b-form-textarea#nested-text(v-bind:class="{'is-invalid': error.text}", rows="3", placeholder="לורם איפסום", v-model="formData.text")
                                    b-form-group(label-cols-sm='3', label="", label-align-sm='right', label-for='sub-btn')
                                        button.btn.btn-warning.w-100.sub-btn(@click.prevent="submit") שלח

</template>


<style lang="scss">
    $orange-text: orange;
    .jumbotron {
        background-color: #091a38 !important;
        padding: 2rem 1rem !important;
        border-radius: 0 !important;

        h2.moto-text {
            font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
            font-weight: 600;
            font-size: 39px;
            color: #f9fbfd;

            span {
                color: orange;
            }
        }

        @media only screen and (max-width: 767px) {
            padding-top: 5rem !important;
        }

    }

    .card-hight {
        height: 345px;
    }

    .sub-btn {
        color: #f9fbfd;
    }

    .no-bullets {
        list-style: none
    }

    th, tr {
        padding: 0.5rem 0;
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .card-hight {
            height: 370px;
        }


    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .card-hight {
            height: 400px;
        }
    }

    @media only screen and (max-width: 767px) {
        .gap-bottom {
            padding-bottom: 6rem;
        }
        .card-hight {
            height: 375px;
        }

        .second-card {
            height: 560px
        }

    }

</style>


<script lang="js">
    import validator from 'validator';
    export default {
        name: 'Contact',
        data() {
            return {
                busy: false,
                formData: {
                    name: '',
                    email: '',
                    phone: '',
                    text: ''
                },
                error: {
                    name: false,
                    email: false,
                    phone: false,
                    text: false
                }
            }
        },
        methods: {
            submit() {
                if (this.validate()) {
                    let loader = this.$loading.show();
                    this.$apix.sendHttpRequest('POST', 'contact-us/submit', {name: this.formData.name, text: this.formData.text, email: this.formData.email, phone: this.formData.phone})
                        .then(res => {
                            console.log(res)
                            setTimeout(() => {
                                loader.hide();
                                this.$toast.success('שאלתך נשלחה לצוות התמיכה שלנו. אחד מנציגינו יחזור אליך בהקדם האפשרי')
                                this.$router.push('thankyou?type=contact-us')
                            }, 2000)
                        })
                        .catch(err => {
                            loader.hide();
                            console.error(err)
                        })
                }
            },
            validate() {
                let error = false;

                this.error = { name: false, email: false, phone: false, text: false };

                if (!this.formData.name) {
                    this.$toast.error('חסר שם מלא');
                    this.error.name = true;
                    error = true;
                }

                if (!this.formData.email) {
                    this.$toast.error('חסר כתובת דוא"ל');
                    this.error.email = true;
                    error = true;
                } else if(!validator.isEmail(this.formData.email)) {
                    this.$toast.error('כתובת דוא"ל לא תקינה');
                    this.error.email = true;
                    error = true;
                }

                if (!this.formData.phone) {
                    this.$toast.error('חסר מספר טלפון');
                    this.error.phone = true;
                    error = true;
                } else if(!validator.isMobilePhone(this.formData.phone, 'he-IL')) {
                    this.$toast.error('מספר טלפון לא תקין');
                    this.error.phone = true;
                    error = true;
                }

                if (!this.formData.text) {
                    this.$toast.error('חסר מלל');
                    this.error.text = true;
                    error = true;
                }

                return !error;
            }
        }
    }
</script>
